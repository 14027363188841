import React from "react"
import Helmet from "react-helmet"

import 'normalize.css';
import { createGlobalStyle } from "styled-components"

import avatarImage from "../images/avatar.jpg"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #222733;
    
    font-family: 'Droid Sans', sans-serif;
    color: #ffffff;
  }
  
  * {
    box-sizing: border-box;
    
    -webkit-font-smoothing: antialiased;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>Verlikylos.dev | Fullstack Developer</title>
        <meta name="title" content="Verlikylos.dev | Fullstack Developer" />
        <meta name="description" content="Hi, my name is Kami and I'm a fullstack developer. During my work I mainly use PHP, Java and React. Feel free to contact me." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://verlikylos.dev/" />
        <meta property="og:title" content="Verlikylos.dev | Fullstack Developer" />
        <meta property="og:description" content="Hi, my name is Kami and I'm a fullstack developer. During my work I mainly use PHP, Java and React. Feel free to contact me." />
        <meta property="og:image" content={avatarImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://verlikylos.dev/" />
        <meta property="twitter:title" content="Verlikylos.dev | Fullstack Developer" />
        <meta property="twitter:description" content="Hi, my name is Kami and I'm a fullstack developer. During my work I mainly use PHP, Java and React. Feel free to contact me." />
        <meta property="twitter:image" content={avatarImage} />
      </Helmet>

      <GlobalStyle />

      {children}
    </>
  )
}

export default Layout
