import React from "react"
import styled, { css } from 'styled-components';

import media from '../utils/media';

const StyledContainer = styled.div`
  width: 100%;
  
  padding: 0 15px;
  margin: 0 auto;
  
  ${media.phone`
    max-width: 540px;
  `}
  
  ${media.tablet`
    max-width: 720px;
  `}
  
  ${media.laptop`
    max-width: 960px;
  `}
  
  ${media.desktop`
    max-width: 1170px;
  `}
  
  ${({maxWidth}) => maxWidth && css`
    max-width: ${maxWidth} !important;
  `}
`;

const Container = ({ className, children, maxWidth }) => {
  return (
    <StyledContainer className={className} maxWidth={maxWidth}>
      {children}
    </StyledContainer>
  )
}

export default Container;
